<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" backdrop="static" @ready="modelReady">
      <div style="position:relative;max-width:320px">
        <h6>
          分组设置
        </h6>
        <div class="" style="position:absolute;top:0;right:0;padding:5px;cursor:pointer;" @click="$refs.model.hideModal()">
          <i class="fa fa-times"></i>
        </div>
        <template v-for="g in [getGroupData(groupGuid)]">
          <div class="form-group has-feedback">
            <label for="addGroupName">名称</label>
            <input type="text" class="form-control" id="addGroupName" v-model="g.title" placeholder="请输入名称" maxlength="20">
            <div v-if="!checkGroupUniqueName(g)" class="text-danger">名称重复</div>
          </div>
          <div class="form-group">
            <label>描述</label>
            <PellEditor v-model.trim="g.description" />
          </div>
          <div class="form-group">
            <button v-if="!g.file" type="button" class="btn btn-outline-primary" @click="showGroupFileLibDialog()">{{g.file?`${g.file.name}`:'选择封面'}}</button>
            <template v-if="g.file&&g.file.isImage">
              <div class="card-tools float-right">
                <button type="button" class="btn btn-box-tool" @click="g.file=null" title="删除"><i class="fas fa-trash"></i></button>
              </div>
              <a>
                <div style="height:100px;background-repeat:no-repeat;background-size:contain;background-color:#ccc;background-position:center" :style="{'background-image':'url(' + g.file.url + ')'}"></div>
              </a>
            </template>
            <template v-else-if="g.file&&g.file.isVideo">
              <div>{{g.file.name}}</div>
              <div class="card-tools float-right">
                <button type="button" class="btn btn-box-tool" @click="g.file=null" title="删除"><i class="fas fa-trash"></i></button>
              </div>
              <video style="width:100%;" :src="g.file.url" :poster="((g.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls />
            </template>
            <template v-else-if="g.file&&g.file.isAudio">
              <button type="button" class="btn btn-outline-primary" @click="showGroupFileLibDialog()">{{g.file?`${g.file.name}`:'选择封面'}}</button>
            </template>
          </div>
          <div class="form-group">
            <button v-if="g.audio" type="button" class="btn btn-outline-primary mb-1" @click="showGroupAudioLibDialog()">音频:{{(g.audio||{}).name||null}}</button>
            <button v-else type="button" class="btn btn-outline-primary mb-1" @click="showGroupAudioLibDialog()">选择音频</button>
            <audio v-if="g.audio" class="form-control" :src="(g.audio||{}).url||null" controls></audio>
          </div>
          <template v-if="g.audio">
            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchItemAudioAutoPlay" v-model.trim="g.audioAutoPlay">
              <label class="custom-control-label" for="customSwitchItemAudioAutoPlay">自动播放</label>
            </div>
            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchItemAudioLoop" v-model.trim="g.audioLoop">
              <label class="custom-control-label" for="customSwitchItemAudioLoop">循环播放</label>
            </div>
          </template>
          <div class="form-group custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitchHide" v-model.trim="g.hide">
            <label class="custom-control-label" for="customSwitchHide">隐藏分组</label>
          </div>
        </template>
        <div style="display:flex;justify-content:flex-end;">
          <!--<button class="btn btn-default btn-sm mr-2" @click="$refs.editGroup.show=false">取消</button>-->
          <button class="btn btn-danger btn-sm mr-2" @click="showDeleteGroup();deleteGroupItem=false">删除</button>
          <button class="btn btn-primary btn-sm" @click="modelHide()">确定</button>
        </div>
      </div>
    </component>
    <component ref="addGroup" :is="getComonent(config.modelName||'Model-1')" backdrop="static" @ready="addGroupModelReady">
      <div>
        <h5>添加分组</h5>
      </div>
      <template v-if="addGroupData">
        <div class="form-group has-feedback">
          <label for="addGroupName">名称</label>
          <input type="text" class="form-control" id="addGroupName" v-model="addGroupData.title" placeholder="请输入名称" maxlength="20">
        </div>
        <div class="form-group has-feedback">
          <label for="addGroupType">类型</label>
          <select type="text" class="form-control" id="addGroupType" v-model="addGroupData.type" placeholder="请输入名称">
            <option value="Pano">全景</option>
            <option value="Blog">博文</option>
            <option value="Gallery">相册</option>
          </select>
        </div>
        <div style="display:flex;justify-content:flex-end;">
          <button class="btn btn-default btn-sm mr-2" @click="addGroupModelHide()">取消</button>
          <button class="btn btn-primary btn-sm" :disabled="!addGroupData.title" @click="addGroup()">添加</button>
        </div>
      </template>
    </component>
    <component ref="deleteGroup" :is="getComonent(config.modelName||'Model-1')" backdrop="static" @ready="deleteGroupModelReady">
      <div>
        <h5>删除分组</h5>
      </div>
      <div>
        确定删除“{{getGroupData(groupGuid).title}}”分组？
      </div>
      <div class="form-group custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="customSwitchDeleteGroupItem" v-model.trim="deleteGroupItem">
        <label class="custom-control-label" for="customSwitchDeleteGroupItem">同时删除分组内场景</label>
      </div>
      <div style="display:flex;justify-content:flex-end;">
        <button type="button" class="btn btn-primary btn-sm mr-2" @click="deleteGroupModelHide()">取消</button>
        <button class="btn btn-danger btn-sm" @click="deleteGroup()">删除</button>
      </div>
    </component>
    <template v-if="urls">
      <FileLib ref="GroupFileLib" :libUrl="urls.fileLib()" :panosUploadUrl="urls.panosUploadUrl()" :filesUploadUrl="urls.filesUploadUrl()" :tagsUrl="urls.privateTags()" :fileUrl="urls.fileUrl()" :panoUrl="urls.panoUrl()" :params="params" @submit="GroupFileLibSelected"></FileLib>
      <AudioLib ref="GroupAudioLib" :libUrl="urls.audioLib()" :params="params" :onSelected="GroupAudioLibSelected"></AudioLib>
    </template>
  </div>
</template>
<script>
  import { uuid } from 'vue-uuid'
  import PellEditor from '../../PellEditor'
  import FileLib from '../../FileLib'
  import AudioLib from '../../AudioLib'

  export default {
    components: {
      PellEditor,
      FileLib,
      AudioLib,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        addGroupModelShow: null,
        addGroupModelHide: null,
        deleteGroupModelShow: null,
        deleteGroupModelHide: null,
        deleteGroupItem: false,
        //groups: []
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      form() {
        return this.publicData.form || {}
      },
      $v() {
        return this.publicData.$v
      },
      addGroupData() {
        return this.publicData.addGroupData
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
      groups() {
        return this.form.groups || []
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          show: this.show,
          addGroup: this.addGroupStart
        },
        events: {
          addGroup: {
            target: 'Data',
            name: 'addGroup',
          },
          deleteGroup: {
            target: 'Data',
            name: 'deleteGroup',
          },
        }
      })
    },
    destroyed() {
    },
    methods: {
      addGroupStart() {
        this.showAddGroup()
        if (!this.addGroupData) {
          this.getFunc('changePublicData')({ addGroupData: this.newGroup(), })
        }
      },
      addGroup() {
        //for (var i in this.form.groups) {
        //    if (this.form.groups[i].title == this.addGroupName) {
        //        this.$message({
        //            message: '分组已经存在',
        //            type: 'info',
        //            zIndex: 9999,
        //        })
        //        return
        //    }
        //}
        var g = this.addGroupData
        if (!this.hasGroup()) {
          if (this.form.groups[0]) {
            this.form.groups[0].title = g.title
            g = this.form.groups[0]
          } else {
            this.$emit('addGroup', { group: g })
            for (var i in this.form.items) {
              this.form.items[i].groupGUID = g.guid
            }
          }
        } else {
          this.$emit('addGroup', { group: g })
        }
        this.getFunc('changePublicData')({ groupGuid: g.guid, addGroupData: this.newGroup(), })
        if (g.type == 'Gallery') {
          this.getFunc('renewSence')('gallery')
        }
        if (g.type == 'Blog') {
          this.getFunc('renewSence')('blog')
        }
        if (g.type == 'Pano') {
          this.getFunc('renewSence')('default')
        }
        this.$refs.addGroup.show = false
      },
      deleteGroup() {
        this.$emit('deleteGroup', { guid: this.groupGuid, deleteGroupItem: this.deleteGroupItem })
        this.deleteGroupModelHide()
        this.modelHide()
      },
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      addGroupModelReady({ funcs }) {
        this.addGroupModelShow = funcs.show
        this.addGroupModelHide = funcs.hide
      },
      deleteGroupModelReady({ funcs }) {
        this.deleteGroupModelShow = funcs.show
        this.deleteGroupModelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      showAddGroup() {
        if (this.addGroupModelShow) {
          this.addGroupModelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      showDeleteGroup() {
        if (this.deleteGroupModelShow) {
          this.deleteGroupModelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      showGroupAudioLibDialog() {
        this.$refs.GroupAudioLib.showDialog();
      },
      GroupAudioLibSelected(select) {
        this.getGroupData(this.groupGuid).audio = select;
      },
      showGroupFileLibDialog() {
        this.$refs.GroupFileLib.showDialog();
      },
      GroupFileLibSelected(select) {
        this.getGroupData(this.groupGuid).file = select;
      },
      getGroupData(guid) {
        for (var i in this.form.groups) {
          if (this.form.groups[i].guid == guid) {
            return this.form.groups[i]
          }
        }
        return this.newGroup()
      },
      hasGroup() {
        for (var i in this.groups) {
          if (this.groups[i].title) {
            return true
          }
        }
        return false
      },
      newGroup(groupName) {
        return {
          guid: uuid.v1(),
          title: groupName,
          description: '',
          audioAutoPlay: true,
          audioLoop: false,
          file: null,
          audio: null,
          type: 'Pano',
        }
      },
      checkGroupUniqueName(p) {
        for (var i in this.form.groups) {
          var item = this.form.groups[i]
          if (item.guid != p.guid && item.title == p.title) {
            return false
          }
        }
        return true
      },
    },
  }
</script>
<style scoped>
  .GroupItem {
    display: inline-block;
    width: auto;
    margin: 2px;
    pointer-events: auto;
    vertical-align: top;
    position: relative;
  }

  .group {
    border-bottom: 2px solid #0000;
    /*background-color: #0008;*/
    padding: 2px 4px;
  }

    .group.active {
      border-bottom: 2px solid #ffb400;
      /*background-color: #00f8*/
    }
</style>
